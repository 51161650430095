<template >
  <v-card class="siteSett">
    <v-list style="margin: 1rem">
      <h4 class="panelTitle">تنظیمات سامانه</h4>
      <hr style="margin-top: 1rem;margin-bottom: 1rem">
      <v-row>
        <v-col>
          <h5>تنظیمات سامانه پیام کوتاه</h5>
        </v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="5"> شارژ باقیمانده:</v-col>
            <v-col><input class="disableInput" :value="remainCredit"></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>ارسال پیام ورود خروج کاربر</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled> فعال</v-btn>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="6">
              <span>ارسال پیام های سیستم</span>
            </v-col>
            <v-col>
              <v-btn color="success" disabled> فعال</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>ارسال کد احراز هویت</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled> فعال</v-btn>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="6">
              <span>ارسال کد واریز و برداشت</span>
            </v-col>
            <v-col>
              <v-btn color="success" disabled> فعال</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <hr style="margin-top: 1rem;margin-bottom: 1rem">
      <v-row>
        <h5>تایید دو مرحله ای</h5>
      </v-row>
      <v-row>
        <v-col>
          <span>اجباری برای ورود به سیستم</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>اجباری برای برداشت از سیستم</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
      </v-row>
      <hr style="margin-top: 1rem;margin-bottom: 1rem">
      <h5 > محدود کردن ورود بر اساس کشور</h5>
      <v-row>
        <v-col>
          <span>فقط کاربران ایرانی مجاز به ورود باشند</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>کشور های غیر مجازبرای ورود به سیستم</span>
        </v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
      </v-row>
      <hr style="margin-bottom: 1rem;margin-top: 1rem">
      <v-row>
        <v-col>احراز هویت اتوماتیک توسط سیستم</v-col>
        <v-col>
          <v-btn color="success" disabled>فعال</v-btn>
        </v-col>
      </v-row>
      <hr style="margin-bottom: 1rem;margin-top: 1rem">
      <h5 >پیش فرض ثبت نام در سیستم</h5>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <span>ایمیل</span>
            </v-col>
            <v-col>
              <v-btn color="success" disabled>فعال</v-btn>
            </v-col>
            <v-col>
              <span>تلفن همراه</span>
            </v-col>
            <v-col>
              <v-btn color="success" disabled>فعال</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col>کشور پیشفرض ثبت نام شماره همراه</v-col>
        <v-col>
          <v-row>
            <v-col><input class="Input"></v-col>
            <v-col>
              <v-btn color="success" disabled>تایید</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-list>
  </v-card>
</template>

<script>
import axios from 'axios';


export default {
  name: "SiteSetting",
  components: {},
  data() {
    return {
      'entranceUserMessage': '',
      'message': '',
      'depositUserMessage': '',
      'withdrawUserMessage': '',
      userLoginHistory: '',
      dialog: false,
      remainCredit: ''
    };
  },
  mounted() {
    this.getRemainCredit();
  },
  methods: {
    getRemainCredit() {
      axios({
        method: "get",
        url: "https://api.kavenegar.com/v1/63766D6F3355326F71502F38683542643167784379496D497A4975336477674D334C5163625358323847513D/account/info.json",
      }).then((data) => {
        this.remainCredit = data.data.entries.remaincredit;
      });
    }
  }
};
</script>

<style scoped>

</style>
